
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import HoriSlide from "@/components/horiSlide/HoriSlide.vue";
import {
  getFarmingList,
  getResourceData,
  igonreFarming,
} from "../../../api/farmer";
import { stateType } from "./homeType";
import { useRouter, Router } from "vue-router";
import { Dialog, Toast } from "vant";
import { previewImage } from "@/utils/util";

const states = {
  page: 1,
  pageSize: 10,
  resourceData: [],
  farmingDataList: [],
  loading: false,
};
export default defineComponent({
  components: {
    HoriSlide,
  },
  setup() {
    const router = useRouter();
    const state = reactive<stateType>(states);
    const { getFarmingDataList, getResourceDataList } = useData(state);
    const {
      onAdd,
      onShowDetail,
      onRecord,
      onIgnore,
      onBottom,
      preview,
      onRefresh,
    } = useEvent(state, router);
    onMounted(() => {
      state.page = 1;
      state.farmingDataList = [];
      getFarmingDataList();
      getResourceDataList();
    });
    return {
      ...toRefs(state),
      onAdd,
      onBottom,
      onShowDetail,
      onRefresh,
      onIgnore,
      onRecord,
      preview,
    };
  },
});

/**
 * 获取接口数据
 */
const useData = (state: stateType) => {
  const getFarmingDataList = async () => {
    const result: any = await getFarmingList({
      page: state.page,
      pageSize: state.pageSize,
    });
    if (result.data.code == 0) {
      const list = result.data.data.records;
      state.farmingDataList = state.farmingDataList.concat(list);
    }
  };

  const getResourceDataList = async () => {
    const result: any = await getResourceData({ lat: 0, lng: 0 });
    if (result.data.code == 0) {
      state.resourceData = result.data.data.records;
    }
  };
  return {
    getFarmingDataList,
    getResourceDataList,
  };
};

/**
 * 事件类hooks
 */
const useEvent = (state: stateType, router: Router) => {
  const onRefresh = () => {
    state.loading = true;
    state.page = 1;
    state.farmingDataList = [];
    const { getFarmingDataList, getResourceDataList } = useData(state);
    getFarmingDataList();
    getResourceDataList();
    const timer = setTimeout(() => {
      state.loading = false;
    }, 1000);
  };
  const onShowDetail = (e: any) => {
    router && router.push("/farmingDetail/" + e.id);
  };
  const onRecord = (e: any) => {
    router &&
      router.push({
        path: "/farmingRecord",
        query: {
          typeId: e.farmingType,
          typeName: e.farmingTypeName,
          name: e.plantFarmingName,
          id: e.id,
        },
      });
  };
  const onAdd = () => {
    router.push("/addResource");
  };
  const onIgnore = (e: any) => {
    Dialog.confirm({ title: "标题", message: "是否忽略该任务？" }).then(() => {
      igonreFarming({
        id: e.id,
      }).then((res: any) => {
        if (res.data.code == 0) {
          Toast({
            message: "已忽略",
          });
          const { getFarmingDataList } = useData(state);
          state.page = 1;
          state.farmingDataList = [];
          getFarmingDataList();
        }
      });
    });
  };

  const onBottom = () => {
    state.page++;
    const { getFarmingDataList } = useData(state);
    getFarmingDataList();
  };

  // 预览图片
  const preview = (e: any) => {
    previewImage(e);
  };

  return {
    onAdd,
    onShowDetail,
    onRecord,
    onIgnore,
    onBottom,
    preview,
    onRefresh,
  };
};
